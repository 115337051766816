
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Satoshi';
 
  src: url('./fonts/Satoshi-Medium.ttf');
  src: url('./fonts/Satoshi-Regular.ttf');
  src: url('./fonts/Satoshi-Variable.ttf');
  src: url('./fonts/Satoshi-Bold.ttf');
  src: url('./fonts/Satoshi-BlackItalic.ttf');
  src: url('./fonts/Satoshi-Light.ttf');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';

  src: url('./fonts/Satoshi-Regular.ttf');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
 
  src: url('./fonts/Satoshi-Medium.ttf');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
 
  src: url('./fonts/Satoshi-Bold.ttf');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
 
  src: url('./fonts/Satoshi-Black.ttf');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Satoshi", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.nav-link.active {
  color: blue;
  border-bottom: 2px solid blue;
}
.bgOne{
  /* The image used */
  background-image: linear-gradient(rgba(51, 51, 51, 0.6), rgba(51, 51, 51, 0.6)), url("./images/Spring.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
}
.bgTwo{
  /* The image used */
  background-image:linear-gradient(rgba(51, 51, 51, 0.6), rgba(51, 51, 51, 0.6)), url("./images/Winter.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
}
.bgThree{
  /* The image used */
  background-image:linear-gradient(rgba(51, 51, 51, 0.6), rgba(51, 51, 51, 0.6)), url("./images/Summer.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
}
.bgFour{
  /* The image used */
  background-image: linear-gradient(rgba(51, 51, 51, 0.6), rgba(51, 51, 51, 0.6)),
  url("./images/Fall.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
}
.term-bg{
  background-image: url("./images/termbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.navlink{
  color: #6B7280;
  font-weight: 700;
  font-size: 18px;
}
.navlink .active{
  color: #333333;
}